import { gql } from "@apollo/client";

export const uploadManualReport = gql`
  mutation uploadManualReport(
    $csvFile: String!
    $month: String!
    $year: Int!
    $organisationId: Int!
  ) {
    UploadManualPayrollReport(
      csvFile: $csvFile
      month: $month
      year: $year
      organisationId: $organisationId
    ) {
      message
      success
      data
    }
  }
`;

export const runPayrollReport = gql`
  mutation runPayrollReport($month: String!, $organisationId: Int!) {
    RunPayrollReport(month: $month, organisationId: $organisationId) {
      message
      success
      data
    }
  }
`;

export const downloadPayrollReportCSV = gql`
  mutation downloadPayrollReportCSV($payrollReportId: Int!) {
    DownloadPayrollReportCSV(payrollReportId: $payrollReportId) {
      message
      success
      data
    }
  }
`;

export const saveAsFinal = gql`
  mutation saveAsFinal($payrollReportId: Int!, $organisationId: Int!) {
    SavePayrollAsFinal(
      payrollReportId: $payrollReportId
      organisationId: $organisationId
    ) {
      message
      success
      data
    }
  }
`;

export const reRunReport = gql`
  mutation reRunReport(
    $month: String!
    $organisationId: Int!
    $payrollReportId: Int!
  ) {
    ReRunPayrollReport(
      month: $month
      organisationId: $organisationId
      payrollReportId: $payrollReportId
    ) {
      message
      success
      data
    }
  }
`;

export const saveAsPending = gql`
  mutation saveAsPending(
    $planLogIds: [Int]
    $organisationId: Int!
    $payrollReportId: Int!
  ) {
    SavePayrollAsPending(
      planLogIds: $planLogIds
      organisationId: $organisationId
      payrollReportId: $payrollReportId
    ) {
      message
      success
      data
    }
  }
`;

export const refreshPlanLog = gql`
  mutation refreshPlanLog(
    $planLogId: Int!
    $organisationId: Int!
    $payrollReportId: Int!
  ) {
    RefreshPayrollPlan(
      planLogId: $planLogId
      organisationId: $organisationId
      payrollReportId: $payrollReportId
    ) {
      message
      success
      data {
        id
        plan_id
        plan_status
        converted_avc
        first_name
        last_name
        ni_number
        payroll_number
        pension_type
        provider
        scheme_type
        employer_contribution
        salary_sacrifice_amount
        total_contribution_amount
      }
    }
  }
`;
