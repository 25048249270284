import SharedHeader from "../../generic-components/shared-header/SharedHeader";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
  TablePagination,
  Box,
  Container,
  Stack,
  TableSortLabel,
  Grid,
} from "@mui/material";
import LoadingButton from "../../generic-components/button";
import { TRACKING_PAYROLL_PAGE_OPTIONS } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { getPayrollEnabledOrg } from "../../../graphql/queries/payrollReport";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import useStepper from "../../../hooks/useStepper";
import Loader from "../../generic-components/loader";
import SearchIcon from "@mui/icons-material/Search";
import IconInputField from "../../generic-components/icon-inputfield";
import { Form, Formik } from "formik";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#391474",
    border: "1px solid #391474",
    color: "#fff",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    padding: "0.625rem",
    fontWeight: "bold",
  },
}));

const StyledTableRow = styled(TableRow)({
  backgroundColor: "transparent",
  border: "1px solid #E3E3E3",
});

const columns = [
  {
    id: "name",
    label: "Organisation Name",
    align: "left",
  },
];

const PayrollOrganisationList = () => {
  const navigate = useNavigate();
  const { setErrorToast, setSaveError } = useStepper();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [payrollOrganisations, setPayrollOrganisations] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");

  const [fetchOrganisations] = useLazyQuery(getPayrollEnabledOrg);

  useEffect(() => {
    getOrganisations();
  }, [page, rowsPerPage, orderBy, order, searchQuery]);

  const transformOrgFilter = (variables) => {
    if (searchQuery) {
      variables.filter.name = { _ilike: `%${searchQuery}%` };
    }
    return variables;
  };

  const getOrganisations = async () => {
    setIsLoading(true);
    let variables = {
      limit: rowsPerPage,
      offset: rowsPerPage * (page - 1),
      filter: {},
      orderBy: { [orderBy]: order },
    };

    fetchOrganisations({
      fetchPolicy: "no-cache",
      refetchWritePolicy: "overwrite",
      variables: transformOrgFilter(variables),
      onCompleted: (data) => {
        setPayrollOrganisations(data?.organisations || []);
        setCount(data?.organisations_aggregate?.aggregate?.count || 0);
        setIsLoading(false);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
        setIsLoading(false);
      },
    });
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(+e?.target?.value);
    setPage(1);
  };

  const handleSortChange = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(1);
  };

  return (
    <Box className="application-page-container mt-150 mb-100">
      <SharedHeader heading="Payroll Organisations" displayLogo={false} />
      <Container>
        <Grid
          container
          sx={{
            alignItems: "center",
          }}
          className="mb-10"
        >
          <Grid item xs={12} md={4}>
            <Formik
              enableReinitialize
              validateOnMount
              initialValues={{ searchOrganisations: searchQuery }}
            >
              {({ values, handleBlur, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <IconInputField
                    id="searchOrganisations"
                    name="searchOrganisations"
                    label="Search Organisation"
                    trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                    value={values?.searchOrganisations}
                    icon={<SearchIcon />}
                    handleChangeValue={(e) => {
                      setSearchQuery(e?.target?.value);
                      setPage(1);
                    }}
                    handleBlurValue={handleBlur}
                  />
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 550 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      sortDirection={orderBy === column.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={() => handleSortChange(column.id)}
                        sx={{
                          "&.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
                            color: "white !important",
                          },
                          "&.MuiTableSortLabel-root": {
                            color: "white !important",
                          },
                          "&.Mui-active": {
                            color: "white !important",
                          },
                        }}
                      >
                        {column?.label}
                      </TableSortLabel>
                    </StyledTableCell>
                  );
                })}
                <StyledTableCell align="center" width="35%" />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={12} align="center">
                    <Loader size={35} />
                  </StyledTableCell>
                </StyledTableRow>
              ) : payrollOrganisations?.length ? (
                payrollOrganisations.map((organisation) => (
                  <StyledTableRow key={organisation?.id}>
                    {columns.map((column) => {
                      return (
                        <StyledTableCell key={column?.id} align="left">
                          {organisation[column?.id]}
                        </StyledTableCell>
                      );
                    })}
                    <StyledTableCell align="center">
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="center"
                      >
                        <LoadingButton
                          buttonTitle="Details"
                          trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                          handleClick={() =>
                            navigate(
                              `/payroll_report/organisation/details/${encodeURIComponent(
                                organisation?.name
                              )}/${organisation?.id}`
                            )
                          }
                          styleClass={"btn primary-clr-btn smallbtn"}
                        />
                        <LoadingButton
                          buttonTitle="Edit"
                          trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                          handleClick={() =>
                            navigate(
                              `/payroll_report/organisation/edit/${encodeURIComponent(
                                organisation?.name
                              )}/${organisation?.id}`
                            )
                          }
                          styleClass={"btn primary-clr-btn smallbtn"}
                        />
                      </Stack>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={12} align="center">
                    No records found
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          page={page - 1}
          rowsPerPageOptions={[4, 8, 12]}
          count={count}
          labelRowsPerPage="Records per page"
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Container>
    </Box>
  );
};

export default PayrollOrganisationList;
